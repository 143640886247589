import "./timeline.css";
import { ReactComponent as WorkIcon } from "./work.svg";
import { ReactComponent as SchoolIcon } from "./school.svg";
import AguaClara from "../img/aguaclara.png";
import timelineElements from "./timelineElements";
import Merck from "../img/merck.png";
import Vesta from "../img/small_vesta.png";
import Cornell from "../img/Cornell_University_Logo.png";
import { TypeAnimation } from "react-type-animation";

import {
    VerticalTimeline,
    VerticalTimelineElement,
} from "react-vertical-timeline-component";

import "react-vertical-timeline-component/style.min.css";
import { icon } from "@fortawesome/fontawesome-svg-core";

function Timeline() {
    let workIconStyles = { background: "#06D6A0" };
    let schoolIconStyles = { background: "#f9c74f" };
    const iconStyles = {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        width: '100%', // Ensures the img tag fills the icon container
        height: '100%', // Ensures the img tag fills the icon container
        // padding: '10%', // Adjust padding to create some space around the image
        boxSizing: 'border-box' // Ensures padding is included in width/height
    };
    /** font-size: 4em;
    text-align: center;
    font-family: "Bebas Neue", sans-serif;
    color: #fff;
    padding-top: 1em; */
    return (
        <div>
            <TypeAnimation
                sequence={[
                    'Work Experience 💼',
                ]}
                wrapper="div"
                speed={25}
                style={{ fontSize: '4em', display: 'block', color: 'white', textAlign: 'center', fontFamily: 'Bebas Neue, sans-serif', paddingTop: '1em', width: '100%' }}
            />
            <VerticalTimeline>
                {timelineElements.map((element) => {
                    let isWorkIcon = element.icon === "work";
                    let showButton =
                        element.buttonText !== undefined &&
                        element.buttonText !== null &&
                        element.buttonText !== "";
                    if (element.icon === "aguaclara") {
                        return (
                            <VerticalTimelineElement
                                key={element.key}
                                date={element.date}
                                dateClassName="date"
                                iconStyle={{ background: "white" }}
                                icon={<img src={AguaClara} alt="AguaClara" style={iconStyles} />}
                            >
                                <h3 className="vertical-timeline-element-title">
                                    {element.title}
                                </h3>
                                <h5 className="vertical-timeline-element-subtitle">
                                    {element.location}
                                </h5>
                                <p id="description">{element.description}</p>
                                {showButton && (
                                    <a
                                        className="button"
                                        href="https://aguaclara.cornell.edu/"
                                        target="_blank"
                                    >
                                        {element.buttonText}
                                    </a>
                                )
                                }
                            </VerticalTimelineElement>
                        );
                    }
                    else if (element.icon === "merck") {
                        return (
                            <VerticalTimelineElement
                                key={element.key}
                                date={element.date}
                                dateClassName="date"
                                iconStyle={{ background: "white" }}
                                icon={<img src={Merck} alt="Merck" style={
                                    iconStyles
                                } />}
                            >
                                <h3 className="vertical-timeline-element-title">
                                    {element.title}
                                </h3>
                                <h5 className="vertical-timeline-element-subtitle">
                                    {element.location}
                                </h5>
                                <p id="description">{element.description}</p>
                                {showButton && (
                                    <a
                                        className="button"
                                        href="https://www.merck.com/"
                                        target="_blank"
                                    >
                                        {element.buttonText}
                                    </a>
                                )}
                            </VerticalTimelineElement>
                        );
                    }
                    else if (element.icon === "vesta") {
                        return (
                            <VerticalTimelineElement
                                key={element.key}
                                date={element.date}
                                dateClassName="date"
                                iconStyle={{ background: "white" }}
                                icon={<img src={Vesta} alt="Vesta" style={iconStyles
                                } />}
                            >
                                <h3 className="vertical-timeline-element-title">
                                    {element.title}
                                </h3>
                                <h5 className="vertical-timeline-element-subtitle">
                                    {element.location}
                                </h5>
                                <p id="description">{element.description}</p>
                                {showButton && (
                                    <a
                                        className="button"
                                        href="/projects"

                                    >
                                        {element.buttonText}
                                    </a>
                                )}
                            </VerticalTimelineElement>
                        );
                    }
                    else if (element.icon === "cornell") {
                        return (
                            <VerticalTimelineElement
                                key={element.key}
                                date={element.date}
                                dateClassName="date"
                                iconStyle={{ background: "white" }}
                                icon={<img src={Cornell} alt="Cornell" style={iconStyles} />}
                            >
                                <h3 className="vertical-timeline-element-title">
                                    {element.title}
                                </h3>
                                <h5 className="vertical-timeline-element-subtitle">
                                    {element.location}
                                </h5>
                                <p id="description">{element.description}</p>
                                {showButton && (
                                    <a
                                        className="button"
                                        href="https://cis.cornell.edu/"
                                        target="_blank"
                                    >
                                        {element.buttonText}
                                    </a>
                                )}
                            </VerticalTimelineElement>
                        );
                    }


                    return (
                        <VerticalTimelineElement
                            key={element.key}
                            date={element.date}
                            dateClassName="date"
                            iconStyle={isWorkIcon ? workIconStyles : schoolIconStyles}
                            icon={isWorkIcon ? <WorkIcon /> : <SchoolIcon />}
                        >
                            <h3 className="vertical-timeline-element-title">
                                {element.title}
                            </h3>
                            <h5 className="vertical-timeline-element-subtitle">
                                {element.location}
                            </h5>
                            <p id="description">{element.description}</p>
                            {showButton && (
                                <a
                                    className={`button ${isWorkIcon ? "workButton" : "schoolButton"
                                        }`}
                                    href=""
                                >
                                    {element.buttonText}
                                </a>
                            )}
                        </VerticalTimelineElement>
                    );
                })}
            </VerticalTimeline>
        </div >
    );
}

export default Timeline;