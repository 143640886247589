// import Grid from '@mui/material/Grid';
// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
// import CardMedia from '@mui/material/CardMedia';
// import Typography from '@mui/material/Typography';
// import { CardActionArea, Link } from '@mui/material';
// import GitHubIcon from '@mui/icons-material/GitHub'; // Import GitHub icon
import IconFab from './IconFab'; // Import the IconFab component
import { projectTools } from './iconsByCategory';
import React, { useState } from 'react';
import { Grid, Card, CardContent, CardMedia, Typography, CardActionArea, Link, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import GitHubIcon from '@mui/icons-material/GitHub';

function ProjectCard({ data }) {
    const { title, description, moreDescription, imageUrl, imageAlt, category, githubUrl, contribution, liveLink } = data;
    const [open, setOpen] = useState(false); // State to handle modal open/close

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const clickLink = () => {
        if (liveLink) {
            window.open(liveLink, '_blank');
        }
    }

    return (
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <Card sx={{ maxWidth: 345, width: '100%' }}>
                <CardActionArea onClick={handleClickOpen}>
                    <CardMedia
                        component="img"
                        height="200"
                        image={imageUrl}
                        alt={imageAlt}
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            {title}
                            {githubUrl && (
                                <Link href={githubUrl} target="_blank" rel="noopener noreferrer" sx={{ ml: 1 }}>
                                    <GitHubIcon />
                                </Link>
                            )}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {description}
                        </Typography>
                    </CardContent>
                    <div style={{ position: 'relative', bottom: 0, right: 0, margin: '10px' }}>
                        <IconFab dictionary={projectTools} category={category} />
                    </div>
                </CardActionArea>
            </Card>

            {/* Modal Dialog */}
            <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
                <DialogTitle sx={{ paddingTop: "35px" }}>{title}</DialogTitle>
                <DialogContent>
                    <CardMedia
                        component="img"
                        height="20%"
                        image={imageUrl}
                        alt={imageAlt}
                        sx={{ marginBottom: 2 }} // Additional spacing
                        onClick={clickLink}
                    />
                    <DialogContentText>{moreDescription}</DialogContentText>
                    {/* Placeholder for additional details */}
                    <Typography variant="h6" gutterBottom>
                        My Involvement
                    </Typography>
                    <DialogContentText>
                        {contribution}
                    </DialogContentText>
                    {/* You can add more sections as needed */}
                    <Typography variant="h6" gutterBottom>
                        Technologies Used
                    </Typography>
                    {/* <DialogContentText> */}
                    <div style={{ position: 'relative', bottom: 0, right: 0, margin: '10px' }}>
                        <IconFab dictionary={projectTools} category={category} />
                    </div>
                    {/* </DialogContentText> */}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                    {githubUrl && (
                        <Button component={Link} href={githubUrl} target="_blank">
                            View on GitHub
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </Grid>
    );
}

export default ProjectCard;
