import React from "react";
import IconCloud from "./IconCloud.tsx";
import IconFab from "./IconFab.jsx";  // Import IconFab
import { iconsByCategory } from './iconsByCategory.jsx';  // Ensure this is correctly imported if needed

class Tools extends React.Component {
  render() {
    return (
      <section id="tools" className="portfolio-mf sect-pt4 route">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="title-box text-center">
                <h3 className="title-a">Tools/Frameworks 🛠️</h3>
                <p className="subtitle-a">
                  A showcase of languages, libraries, and frameworks I use.
                </p>
                <div className="line-mf-main"></div>
              </div>
              <div className="cloud-grid">
                {Object.keys(iconsByCategory).map((category) => (
                  <div key={category} className="cloud-item">
                    <h4 className="title-a">{category}</h4>
                    <div className="line-mf"></div>
                    <IconCloud category={category} />
                    <IconFab dictionary={iconsByCategory} category={category} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section >
    );
  }
}

export default Tools;
