import React from "react";
import "./stars.scss";
import "./Background.css";
// import "./back.css";
import { TypeAnimation } from 'react-type-animation';
// import "./Background.css"
// import { Typed } from "react-typed";
import { motion } from 'framer-motion';
import Navbar from './NavBar';
class Intro extends React.Component {
  render() {
    return (
      <div>
        <Navbar changeOnScroll={true} />

        <div id="home" className="intro route bg-image background">
          <motion.div className="intro-content display-table"
            initial={{ opacity: 0, scale: 1 }}
            animate={{ opacity: 1, scale: 1, transition: { duration: 0.5, ease: "easeInOut" } }}
            exit={{ opacity: 0, scale: 1, transition: { duration: 0.5, ease: "easeInOut" } }}
          >
            <div className="table-cell">
              <div className="container">
                <h1 className="intro-title mb-4">Hey 👋, It's Shreehari!</h1>
                <p className="intro-subtitle">
                  <span className="text-slider-items"></span>
                  <strong className="text-slider">
                    <TypeAnimation
                      sequence={[
                        'CS @ Cornell • Software Engineer 💻',
                        1000,
                        'CS @ Cornell • Backend Engineer 🔨',
                        1000,
                        'CS @ Cornell • Data Scientist 🧪',
                        1000
                      ]}
                      wrapper="span"
                      speed={50}
                      style={{ fontSize: '1em', display: 'inline-block', color: 'white' }}
                      repeat={Infinity}
                    />
                  </strong>
                </p>
                <div className="pt-3">
                  <div className="intro-button">
                    <a
                      className="btn btn-primary btn js-scroll px-4 btn-margin-right"
                      href="#about"
                      role="button"
                    >
                      Learn More ↓
                    </a>
                    <a
                      className="btn btn-primary btn js-scroll px-4"
                      href="/projects"
                      role="button"
                    >
                      Projects →
                    </a>
                  </div>
                </div>

              </div>
            </div>
          </motion.div>
          <div class="area" >
            <ul class="circles">
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </div >
        </div>
      </div>
    );
  }
}

export default Intro;
