import React from "react";
import myImage from "../img/headshot.jpeg";
import AOS from 'aos';


class About extends React.Component {
  componentDidMount() {
    this.initAOS();
  }

  initAOS() {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }


  render() {
    return (
      <section id="about" className="about-mf sect-pt4 route">
        <div className="box-pl2">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="about-img">
                  <img src={myImage} className="img-fluid rounded b-shadow-a" alt="Shreehari" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="about-info">
                  <h2>About Me</h2>
                  <p>
                    Hello! My name is <span className="highlight">Shreehari</span> and I am a Junior
                    at <span className="highlight">Cornell University</span> studying Computer Science.
                    Currently, I am a Subteam Lead at <span className="highlight">AguaClara Cornell </span>
                    and a <span className="highlight">Teaching Assistant</span> for  Cornell's Operating
                    Systems course. Previously I was a <span className="highlight">Software Engineering Intern</span> at
                    <span className="highlight"> Merck</span>. My interests and skills lie within the Backend tech stack as well as <span className="highlight">Machine
                      Learning/Computer Vision</span>.
                  </p>
                </div>
                <div className="button-container">
                  <a
                    className="btn btn-primary btn js-scroll px-4"
                    href="#tools"
                    role="button"
                  >
                    Tools/Frameworks ↓
                  </a>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default About;
