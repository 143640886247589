import React from "react";
import IconCloud from "./IconCloud.tsx";
import IconFab from "./IconFab.jsx";
import { iconsByCategory } from './iconsByCategory.jsx';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';

import './new_tools.css';

// import './styles.css';
class NewTools extends React.Component {
    render() {
        return (
            <section id="tools" className="portfolio-mf sect-pt4 route">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="title-box text-center">
                                <h3 className="title-a">Tools/Frameworks 🛠️</h3>
                                <p className="subtitle-a">
                                    A showcase of languages, libraries, and frameworks I use.
                                </p>
                                <div className="line-mf-main"></div>
                            </div>
                            {/* <div className="cloud-grid"> */}
                            {/* <div className="cloud-item"> */}
                            <Swiper
                                cssMode={true}
                                navigation={true}
                                pagination={true}
                                mousewheel={true}
                                loop={true}
                                keyboard={true}
                                modules={[Navigation, Pagination, Keyboard]}
                                className="mySwiper"
                                style={{ height: 'auto', paddingBottom: '25px' }}
                            >
                                {Object.keys(iconsByCategory).map((category) => (
                                    <SwiperSlide key={category}>
                                        <div className="swiper-item" onClick={() => console.log('Swiper item clicked')}>
                                            <h4 className="title-b">{category}</h4>
                                            <div className="line-mf"></div>
                                            <IconCloud category={category} />
                                            <IconFab dictionary={iconsByCategory} category={category} />
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                            {/* </div> */}
                            {/* {Object.keys(iconsByCategory).map((category) => (
                                    <div key={category} className="cloud-item">
                                        <h4 className="title-a">{category}</h4>
                                        <div className="line-mf"></div>
                                        <IconCloud category={category} />
                                        <IconFab dictionary={iconsByCategory} category={category} />
                                    </div>
                                ))} */}
                        </div>
                    </div>
                </div>
            </section >
        );
    }
}

export default NewTools;
