import React from 'react';
import { Cloud, renderSimpleIcon } from 'react-icon-cloud';
import { iconsByCategory } from './iconsByCategory.jsx';
import { useTheme, invertBg, lightTheme, ThemedH1, ThemeProvider } from "./use-theme.tsx";
import { cloudProps } from './cloudStuff.tsx';



// Import more icons as needed

const IconCloud = ({ category }) => {
    const { color } = useTheme()
    const icons = iconsByCategory[category].map((icon) => {

        return renderSimpleIcon({
            icon,
            minContrastRatio: color === lightTheme.color ? 1.2 : 2,
            bgHex: color,
            size: 100,
            fallbackHex: invertBg(color),
            aProps: {
                href: undefined,
                target: undefined,
                rel: undefined,
                onClick: (e: any) => e.preventDefault()
            }
        })
    });

    return <Cloud {...cloudProps}>{icons}</Cloud>;
};

export default IconCloud;
