import React from 'react';
import { Routes, Route } from 'react-router-dom';

// Import CSS in order
import 'normalize.css';
import './animate.css';
import 'bootstrap/dist/css/bootstrap.css';
import './img/icons/css/ionicons.css';
import './img/font-awesome/css/font-awesome.css';
import 'lightbox2/dist/css/lightbox.min.css'
import 'aos/dist/aos.css';
import './style.css';

// Import JS libraries
import 'jquery/dist/jquery.min.js';
import 'popper.js/dist/umd/popper.min.js'; // Make sure to use the UMD version for Popper.js if using it directly
import 'bootstrap/dist/js/bootstrap.min.js';
import './libs/easing.js';
import 'lightbox2/dist/js/lightbox.min.js';

// Import components
import Navbar from './components/NavBar.js';
import BackToTop from './components/back-top.jsx';
import AnimatedRoutes from './components/AnimatedRoutes.jsx';
const App = () => (
    <React.Fragment>
        <AnimatedRoutes />
        <BackToTop />
    </React.Fragment>
);

export default App;
