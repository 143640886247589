import React, { Component } from 'react';
import { FaGithub, FaLinkedin, FaFilePdf, FaEnvelope } from 'react-icons/fa'; // Social media icons
import './Footer.css'; // Ensure you import your stylesheet

export class Footer extends Component {
    render() {
        const footerClass = this.props.whiteBackground ? "footer-white" : "footer";
        return (
            <footer className={footerClass}>
                <div className="container">
                    <div className="col-sm-12 col-md-6">
                        <p>Made with ❤️</p>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <ul className="footer-social-icons">
                            <li><a href="mailto:ss2732@cornell.edu" target="_blank" rel="noopener noreferrer"><FaEnvelope /></a></li>
                            <li><a href="" target="_blank" rel="noopener noreferrer"><FaFilePdf /></a></li>
                            <li><a href="https://www.linkedin.com/in/shreehari-srinivasan-843469177/" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a></li>
                            <li><a href="https://github.com/zenshreee" target="_blank" rel="noopener noreferrer"><FaGithub /></a></li>
                        </ul>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;
