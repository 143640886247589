// ProjectsGrid.jsx
import React from 'react';
import ProjectCard from './projectCard';
import './ProjectGrid.css'; // Assume this is your CSS file for styling the grid

function ProjectsGrid({ data }) {
    return (
        <div className="grid-container">
            {data.map((project, index) => (
                <ProjectCard key={index} data={project} />
            ))}
        </div>
    );
}

export default ProjectsGrid;
