import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// Import images
import supreme from "../img/supreme_dunk.gif"
import jordan_unc from "../img/jordan_unc.gif"
import offwhite from "../img/offwhite.gif"
import kobe from "../img/kobe.gif"

class Shoes extends React.Component {
    render() {
        return (
            <section id="work" className="portfolio-mf sect-pt4 route">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="title-box text-center">
                                <h3 className="title-a">My Sneaker Collection 👟</h3>
                                <p className="subtitle-a">
                                    Some sneakers I own and love.
                                </p>
                                <div className="line-mf-main"></div>
                            </div>

                            <Swiper
                                cssMode={true}
                                navigation={true}
                                pagination={true}
                                mousewheel={true}
                                loop={true}
                                keyboard={true}
                                modules={[Navigation, Pagination, Keyboard]}
                                className="mySwiper"
                                style={{ height: 'auto', paddingBottom: '25px' }}
                            >
                                <SwiperSlide>
                                    <div className="swiper-item">
                                        <h4 className="title-b">Nike Dunk Supreme - Mean Green</h4>
                                        <div className="image-container">
                                            <img src={supreme} alt="supreme" className="sneaker-animation" />
                                            <div className="overlay">
                                                <div className="text">Nike Dunk Supreme - Mean Green - A green and white colorway, perfect for streetwear.</div>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-item">
                                        <h4 className="title-b">Jordan 1 Hi OG - University Blue</h4>
                                        <div className="image-container">
                                            <img src={jordan_unc} alt="jordan" className="sneaker-animation" />
                                            <div className="overlay">
                                                <div className="text">Jordan 1 Hi OG - University Blue - Worn by Michael Jordan himself, a classic sneaker for any collection.</div>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-item">
                                        <h4 className="title-b">Jordan 4 Off-White - Sail</h4>
                                        <div className="image-container">
                                            <img src={offwhite} alt="offwhite" className="sneaker-animation" />
                                            <div className="overlay">
                                                <div className="text">Jordan 4 Off-White - Sail - A collaboration between Virgil Abloh and Nike, a unique women's sneaker that stands out.</div>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-item">
                                        <h4 className="title-b">Kobe V Protro - Big Stage/Parade</h4>
                                        <div className="image-container">
                                            <img src={kobe} alt="kobe" className="sneaker-animation" />
                                            <div className="overlay">
                                                <div className="text">Kobe V Protro - Big Stage/Parade - An iconic shoe for an iconic player.</div>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Shoes;
