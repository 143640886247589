import js from "simple-icons/icons/javascript";
import react from "simple-icons/icons/react";
import node from "simple-icons/icons/nodedotjs";
import docker from "simple-icons/icons/docker";
import python from "simple-icons/icons/python";
import go from "simple-icons/icons/go";
import java from "simple-icons/icons/java";
import ocaml from "simple-icons/icons/ocaml";
import html from "simple-icons/icons/html5";
import css from "simple-icons/icons/css3";
import sql from "simple-icons/icons/mysql";
import bash from "simple-icons/icons/gnubash";
import markdown from "simple-icons/icons/markdown";
import latex from "simple-icons/icons/latex";
import angular from "simple-icons/icons/angular";
import tailwind from "simple-icons/icons/tailwindcss";
import jQuery from "simple-icons/icons/jquery";
import Bootstrap from "simple-icons/icons/bootstrap";
import gatsby from "simple-icons/icons/gatsby";
import vue from "simple-icons/icons/vuedotjs";
import nextjs from "simple-icons/icons/nextdotjs";
import express from "simple-icons/icons/express";
import django from "simple-icons/icons/django";
import flask from "simple-icons/icons/flask";
import spring from "simple-icons/icons/spring";
import nginx from "simple-icons/icons/nginx";
import apache from "simple-icons/icons/apachekafka";
import kubernetes from "simple-icons/icons/kubernetes";
import mongo from "simple-icons/icons/mongodb";
import redis from "simple-icons/icons/redis";
import rabbitmq from "simple-icons/icons/rabbitmq";
import aws from "simple-icons/icons/amazonaws";
import gcp from "simple-icons/icons/googlecloud";
import azure from "simple-icons/icons/microsoftazure";
import heroku from "simple-icons/icons/heroku";
import vscode from "simple-icons/icons/visualstudiocode";
import git from "simple-icons/icons/git";
import github from "simple-icons/icons/github";
import gitlab from "simple-icons/icons/gitlab";
import jenkins from "simple-icons/icons/jenkins";
import jira from "simple-icons/icons/jira";
import confluence from "simple-icons/icons/confluence";
import slack from "simple-icons/icons/slack";
import postman from "simple-icons/icons/postman";
import microsoft_teams from "simple-icons/icons/microsoftteams";
import c from "simple-icons/icons/c";
import cpp from "simple-icons/icons/cplusplus";
import json from "simple-icons/icons/json";
import numpy from "simple-icons/icons/numpy";
import pytorch from "simple-icons/icons/pytorch";
import tensorflow from "simple-icons/icons/tensorflow";
import opencv from "simple-icons/icons/opencv";
import scikitlearn from "simple-icons/icons/scikitlearn";
import S3 from "simple-icons/icons/amazons3";
import dynamo from "simple-icons/icons/amazondynamodb";
import cockroach from "simple-icons/icons/cockroachlabs";
import google_maps from "simple-icons/icons/googlemaps";
import graphql from "simple-icons/icons/graphql";
const iconsByCategory = {
    Languages: [js, python, go, java, ocaml, html, css, sql, bash, markdown, latex, c],
    Backend: [node, django, flask, kubernetes, sql, aws, gcp, heroku, azure, spring],
    Frontend: [react, nextjs, angular, tailwind, jQuery, Bootstrap, vue],
    Tools: [docker, vscode, git, github, gitlab, jira, confluence, slack, postman, microsoft_teams],
};

const projectTools = {
    diagnoSys: [python, html, css, sql, numpy, scikitlearn],
    Vesta: [python, go, react, graphql, aws],
    Floc: [python, react, flask, pytorch, opencv,],
    Gambit: [python, go, cockroach, react, aws],
    Drone: [python, google_maps, json, numpy],
}

export { iconsByCategory, projectTools };