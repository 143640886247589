// Projects.jsx
import React from 'react';
import { motion } from 'framer-motion';
import Navbar from './NavBar';
import Footer from './Footer';
import ProjectsGrid from './ProjectsGrid';
import './project.css';
import { TypeAnimation } from 'react-type-animation';

const Projects = () => {
    const projectsData = [
        // Repeat or map your project objects here
        {
            title: "diagnoSys",
            description: "diagnoSys is a web application that suggests the most likely drugs causing a patient's adverse drug reactions. It uses Singular Value Decomposition and other text mining techniques to produce the results.",
            moreDescription: "diagnoSys is a web application that suggests the most likely drugs causing a patient's adverse drug reactions. It uses Singular Value Decomposition and other text mining techniques to produce the results. You can view a live demo of the application by clicking the image above.",
            imageUrl: "https://i.ibb.co/vXjyjxN/diagnosys.jpg",
            imageAlt: "diagnoSys",
            category: "diagnoSys",
            githubUrl: "https://github.com/Zenshreee/diagnoSys",
            contribution: "I worked with a team of 4 to develop the backend of the application using Flask. I also was heavily involved in incorporating similarity metrics to improve the results of our model. I worked with Singular Value Decomposition to reduce the dimensionality of the data and improve the performance of our model. I also implemented Rocchio's Algorithm to improve the results based on relevance feedback.",
            liveLink: "http://4300showcase.infosci.cornell.edu:5164"
        },
        {
            title: "Vesta",
            description: "A checkout automation tool that helps users buy limited-edition products from various websites. It uses innovative techniques to bypass bot protection and purchase products faster than any human can.",
            moreDescription: "Vesta is a checkout automation tool that helps users buy limited-edition products from various websites. It uses innovative techniques to bypass bot protection and purchase products faster than any human can. Our application has been used to purchase over $500,000 worth of limited edition products. Users have been able to purchase products from Supreme, Nike, Adidas, and many more, profiting over $150,000 in the process. The image above is the user interface of the application. ",
            imageUrl: "https://i.ibb.co/frfvYN2/vesta-ui-graphic.png",
            category: "Vesta",
            imageAlt: "vesta",
            githubUrl: "",
            contribution: "I worked on Vesta with two other developers. I was involved in the development of the backend of the application written in Go. I was also in charge of business operations and marketing for the application, growing our user base to over 200 users in the first month of launch. "
        },

        {
            title: "Floc and Size Count App",
            description: "Floc App is an offline application that helps size and count small particles in the water to help plant operators add the right amount of coagulant to the water. It utilizes YOLOv8 and OpenCV to detect and count the particles.",
            moreDescription: "Floc App is an offline application that helps size and count small particles in the water to help plant operators add the right amount of coagulant to the water. It utilizes YOLOv8 and OpenCV to detect and count the particles. The application is used by AguaClara plants in Honduras and India to help improve the quality of water for over 100,000 people. The code is open-source and can be found on the AguaClara Github or by clicking the image above.",
            imageUrl: "https://i1.wp.com/cornellsun.com/wp-content/uploads/2019/07/Untitled-design-1.png?fit=1024%2C512&ssl=1",
            imageAlt: "floc",
            category: "Floc",
            githubUrl: "https://github.com/AguaClara/floc_app",
            contribution: "I am the subteam lead for the Floc and Size Count App subteam. I have been involved in the development for 2 years now. I have mainly worked on the machine learning sizing model and the integration of the model with the rest of the application. IN 2024, I shifted my focus to the frontend and backend of the application, working with Flask and React to improve the user experience.",
            liveLink: "https://github.com/AguaClara/floc_app"
        },

        {
            title: "Gambit Betting",
            description: "Gambit Betting is a web application that allows users to scout arbitrage opportunities in the betting market. It also allows users to find positive expected value bets.",
            moreDescription: "Gambit Betting is a web application that allows users to scout arbitrage opportunities in the betting market. It also allows users to find positive expected value bets.",
            imageUrl: "https://pbs.twimg.com/media/GFnNWSAXoAE1iDM?format=jpg&name=medium",
            imageAlt: "gambit",
            category: "Gambit",
            githubUrl: "",
            contribution: "Currently, this project is in development. I am working on the backend of the application mainly with database design and integration. A large part of my work also involves scraping data from various betting sources to provide real-time data to the users.",
        },

        {
            title: "Drone Scheduling",

            description: "A small dive into the world of drone route scheduling and a proposal for a new algorithm to offer a unique solution to the problem. We utilize Google OR-Tools to evaluate the performance.",
            moreDescription: "A small dive into the world of drone route scheduling and a proposal for a new algorithm to offer a unique solution to the problem. We utilize Google OR-Tools to evaluate the performance. You can view the paper by cliking the image above. This was a research project for CS 6860 taught by Prof. Jon Kleinberg.",
            imageUrl: "https://i.ibb.co/R9qg22b/TSPTour.jpg",
            imageAlt: "drone",
            category: "Drone",
            githubUrl: "https://github.com/Zenshreee/final-paper",
            contribution: "This was a research project that I worked on with a team of 4. I was heavily involved in the development of the improved algorithm and the incorporation of Google OR-Tools to evaluate the performance of our algorithm. I was also involved in the analysis of different graph structures and their impact on the performance of our algorithm.",
            liveLink: "https://drive.google.com/file/d/1qm-nJ67UYnhKZ-Xmie1mZ4q3v7t-r8oz/view"
        },

    ];

    return (
        <div>
            <Navbar changeOnScroll={false} />
            <div className="experience-background">
                <div className="area">
                    <ul className="circles">
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>

                    <motion.div
                        initial={{ opacity: 0, scale: 0.98 }}
                        animate={{ opacity: 1, scale: 1, transition: { duration: 0.5, ease: "easeInOut" } }}
                        exit={{ opacity: 0, scale: 0.98, transition: { duration: 0.5, ease: "easeInOut" } }}
                    >
                        <TypeAnimation
                            sequence={[
                                'My Projects 🚀',
                            ]}
                            wrapper="div"
                            speed={10}
                            style={{ fontSize: '4em', display: 'block', color: 'white', textAlign: 'center', fontFamily: 'Bebas Neue, sans-serif', paddingTop: '1em', width: '100%' }}
                        />
                        <div className="project-container">
                            <ProjectsGrid data={projectsData} />
                        </div>
                        <Footer />
                    </motion.div>
                </div>
            </div>
        </div>
    );
};

export default Projects;
