import React from 'react';
import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';
import { iconsByCategory } from './iconsByCategory'; // assuming you export them from a file
import './iconFab.css'; // assuming you have a CSS file for styling

const IconFab = ({ dictionary, category }) => {
    return (
        <div className="fab-container">
            {dictionary[category].map((icon) => (
                <Fab
                    variant="extended"
                    size="small"
                    color="primary"
                    key={icon.slug}
                    sx={{
                        textTransform: 'none',
                        color: 'black',
                        backgroundColor: '#fff',
                        '&:hover': {
                            backgroundColor: '#fff', // Keep the background color the same on hover
                            '@media (hover: none)': {
                                backgroundColor: '#fff', // Ensures the background doesn't change even on devices that can't hover
                            }
                        },
                        '&:active': {
                            backgroundColor: '#fff', // Keep the background color the same on click
                            boxShadow: 'none', // Remove the box shadow on click

                        },
                        pointerEvents: 'none', // Disable pointer events to prevent clicking
                    }}
                    disableRipple={true} // This prop removes the ripple effect on click
                // disabled={true} // This prop disables the button
                >
                    <div
                        style={{
                            width: 24,
                            height: 24,
                            marginRight: 8,
                            fill: `#${icon.hex}`, // Set the fill for SVGs
                        }}
                        dangerouslySetInnerHTML={{ __html: icon.svg }}
                    />
                    <Typography variant="caption">{icon.title}</Typography>
                </Fab>
            ))}
        </div>
    );
};

export default IconFab;
