import { ICloud } from "react-icon-cloud";

export const cloudProps: Omit<ICloud, 'children'> = {
    containerProps: {
        style: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "40%",  // Set to 40% of the Swiper slide
            height: "40%",  // Set to 40% of the Swiper slide
            margin: "auto", // This ensures it stays centered
            position: "relative", // Ensures it is positioned relative to its normal position
            top: "50%",  // Moves the top edge to the middle
            // transform: "translateY(-50%)" // Shifts it up by half its own height
        }
    },
    options: {
        clickToFront: 500,
        depth: 1,
        initial: [0.1, -0.1],
        outlineColour: "#0000",
        reverse: true,
        tooltip: "native",
        tooltipDelay: 0,
        wheelZoom: false
    }
}