import React from 'react';
import './experience-background.css';
import './Experience.css';
import { motion } from 'framer-motion';
import Timeline from './timeline';
import Footer from './Footer';
import Navbar from './NavBar';

class Experience extends React.Component {
    render() {
        return (
            <div>
                <Navbar changeOnScroll={false} />

                <div className="experience-background">

                    <div class="area" >
                        <ul class="circles">
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                        </ul>
                        <motion.div initial={{ opacity: 0, scale: 0.98 }}
                            animate={{ opacity: 1, scale: 1, transition: { duration: 0.5, ease: "easeInOut" } }}
                            exit={{ opacity: 0, scale: 0.98, transition: { duration: 0.5, ease: "easeInOut" } }}>
                            <div className="timeline-container">
                                <Timeline />
                            </div>
                            <Footer />
                        </motion.div>
                    </div>


                </div >

            </div>
        );
    }
}

export default Experience;
