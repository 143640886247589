import React from 'react'
import Intro from './intro.jsx';
import About from './about.jsx';
import Tools from './tools.jsx';
import Contact from './contact.jsx';
import Projects from './Projects.jsx';
import Shoes from './shoes.jsx';
import Footer from './Footer.jsx';
import Experience from './experience.jsx';
import NewTools from './new_tools.jsx';


import { useLocation, Routes, Route } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

function AnimatedRoutes() {
    const location = useLocation();
    return (
        <AnimatePresence>
            <Routes location={location} key={location.pathname}>
                <Route path="/" element={<><Intro /><About /><NewTools /><Shoes /><Footer /></>} />
                <Route path="/experience" element={<Experience />} />
                <Route path="/projects" element={<Projects />} />
                <Route path="/contact" element={<Contact />} />
            </Routes>
        </AnimatePresence>
    )
}

export default AnimatedRoutes
