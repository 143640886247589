let timelineElements = [
    {
        id: 1,
        title: "AguaClara Cornell",
        location: "Software Lead - Ithaca, NY",
        description:
            "Leading a team of developers to create a web application that utilizes Convolutional Neural Networks to detect particles in water samples. The application serves over 100,000 people in Honduras and India.",
        buttonText: "Learn More!",
        date: "September 2021 - Present",
        icon: "aguaclara",
    },
    {
        id: 2,
        title: "Cornell University",
        location: "Teaching Assistant - Ithaca, NY",
        description:
            "Helping students of CS 3410 (Computer Organization and Programming) and CS 4410 (Operating Systems) understand course material and complete assignments. Helped grade exams, projects, and homework.",
        buttonText: "Learn More!",
        date: "September 2023 - Present",
        icon: "cornell",
    },
    {
        id: 3,
        title: "Merck & Co.",
        location: "Software Engineering Intern - Rahway, NJ",
        description:
            "Developed an internal tooling application to centralize CAB requests and approvals. Integrated the application with Jira by developing a custom connector to automate the creation of Jira tickets and allow users to track their requests progress.",
        buttonText: "Learn More!",
        date: "June 2023 - August 2023",
        icon: "merck",
    },
    {
        id: 4,
        title: "Vesta Technologies",
        location: "Co-Founder and Developer - Chantilly, VA",
        description:
            "Co-founded a startup that assists with the sneaker acquisition process. Developed software to automate the checkout process for limited-edition sneaker releases. Our users have successfully purchased over $200,000 worth of sneakers, turning a profit of over $45,000.",
        buttonText: "See the Project →",
        date: "July 2020 - September 2021",
        icon: "vesta",
    },
];

export default timelineElements;