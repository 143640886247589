import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './NavBar.css';

const Navbar = (props) => {
    const [colorChange, setColorChange] = useState(false);
    const [navExpanded, setNavExpanded] = useState(false);

    const listenScrollEvent = () => {
        if (window.scrollY > 965) {
            setColorChange(true);
        } else {
            setColorChange(false);
        }
    };

    useEffect(() => {
        if (props.changeOnScroll) {
            window.addEventListener('scroll', listenScrollEvent);
            return () => {
                window.removeEventListener('scroll', listenScrollEvent);
            };
        }
    }, [props.changeOnScroll]);

    const toggleNav = () => {
        setNavExpanded(prevNavExpanded => !prevNavExpanded);
    };

    return (
        <nav className={colorChange ? 'navbar colorChange' : 'navbar'}>
            <div className="navbar-toggler" onClick={toggleNav}>
                ☰
            </div>
            <ul className={`navbar-nav ${navExpanded ? 'show' : ''}`}>
                <li><Link to="/" onClick={toggleNav}>Home</Link></li>
                <li><Link to="/experience" onClick={toggleNav}>Experience</Link></li>
                <li><Link to="/projects" onClick={toggleNav}>Projects</Link></li>
                <li><Link to="/contact" onClick={toggleNav}>Contact</Link></li>
            </ul>
        </nav>
    );
};

export default Navbar;
