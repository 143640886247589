import React from 'react';
import './Background.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { motion } from 'framer-motion';
import Navbar from './NavBar';

const Contact = () => {
  return (
    <div>
      <Navbar changeOnScroll={false} />
      <div id="home" className="intro route bg-image background">
        <motion.div className="intro-content display-table"
          initial={{ opacity: 0, scale: 0.98 }}
          animate={{ opacity: 1, scale: 1, transition: { duration: 0.5, ease: "easeInOut" } }}
          exit={{ opacity: 0, scale: 0.98, transition: { duration: 0.5, ease: "easeInOut" } }}
        >
          <div className="table-cell">
            <div className="container">
              <p className="intro-subtitle">
                <strong className="text-slider">
                  <div className="contact-title">
                    Feel free to reach out! 📧
                  </div>
                </strong>
              </p>
              <div className="contact-icons">
                <a href="mailto:ss2732@cornell.edu" target="_blank" title="Email">
                  <FontAwesomeIcon icon={faEnvelope} size="8x" />
                </a>
                <a href="" target="_blank" rel="noopener noreferrer" title="Resume">
                  <FontAwesomeIcon icon={faFilePdf} size="8x" />
                </a>
                <a href="https://www.linkedin.com/in/shreehari-srinivasan-843469177/" target="_blank" rel="noopener noreferrer" title="LinkedIn">
                  <FontAwesomeIcon icon={faLinkedin} size="8x" />
                </a>
                <a href="https://github.com/zenshreee" target="_blank" rel="noopener noreferrer" title="GitHub">
                  <FontAwesomeIcon icon={faGithub} size="8x" />
                </a>
              </div>

            </div>
          </div>
        </motion.div>
        <div className="area">
          <ul className="circles">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
      </div>
    </div>

  );
}

export default Contact;
